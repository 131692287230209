:root{
    --main-base-color:#3359a4;
    --secondary-base-color:#3359a4;
    --petrol-blue-color:#283a68;
    --blue-upm-color: #3359a4;
    --dark-blue-color:#283a68;
}

.btn-primary{
    background-color: var(--dark-blue-color) !important;
    border-color: var(--dark-blue-color) !important;
}

.btn-outline-primary{
    border-color: var(--dark-blue-color) !important;
    color: var(--dark-blue-color) !important;
}

.btn-outline-primary:hover{
    background-color: var(--dark-blue-color) !important;
    color: white !important;
}

.cta-btn span.title {
    font-size: 1.3rem;
    text-transform: uppercase;
}

.landing-bg{
    background: linear-gradient(rgba(43, 51, 76,.5), rgba(43, 51, 76,.5)), url('/media/bg/main-bg-landing.jpg');
}

.landing-bg .logo{
/* background-color: hsla(0,0%,100%,.6); */
    border-radius: 0 0 5px 5px;
    margin-top: -4rem!important;
    max-width: 40%;
    padding: 0;
}

.label125 {
    font-size: 1.25rem !important;
    font-weight: 501 !important;
}

.form-control.form-control-solid{
    border: 1px solid rgba(43, 51, 76,.3) !important;
}

.form-control.form-control-solid[type=text]:focus {
    -webkit-box-shadow: 0 0 1px 1px rgb(43, 51, 76 / 20%);
    -moz-box-shadow: 0 0 1px 1px rgba(43, 51, 76,.2);
    box-shadow: 0 0 1px 1px rgb(43, 51, 76 / 20%);
}

.welcome {
    position: relative;
    padding: 25px;
}
.welcome hr {
    border: none;
    background-color: #fff;
    position: absolute;
    margin: 0;
}

.welcome hr.vertical {
    width: 10px;
    height: 42%;
    left: 0;
    top: 0;
}
.welcome hr.horizontal {
    width: 18%;
    height: 10px;
    left: 0;
    top: 0;
}
.welcome hr.horizontal.ii, .welcome hr.vertical.ii {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
}

.divider {
    width: 100%;
    text-align: center;
    position: relative;
    height: 30px;
    margin-bottom: 8px;
}

.divider span {
    background-color: white;
    position: absolute;
    top: 0;
    left: calc(50% - 1rem);
    padding-left: 12px;
    padding-right: 12px;
    color: #ccc;
    font-size: 18px;
    line-height: 30px;
    display: block;
}

.divider hr {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    top: 50%;
}

/*#kt_aside, .aside-menu {
    background-color: var(--dark-blue-color)  !important;
}*/

#kt_header{
    background-color: var(--dark-blue-color);
}
#kt_header .dropdown button {
    border: solid 1px white;
}
#kt_header .dropdown button:hover, #kt_header .dropdown button:active, #kt_header .dropdown button:focus{
    border: solid 1px white;
    background-color: rgba(255, 255, 255, 0.2) !important;
}
#kt_header .dropdown button span{
    color: white !important;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow{
    color: var(--dark-blue-color) !important;
}
.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title{
    color: var(--dark-blue-color) !important;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title{
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    margin-top: 0.75rem !important;
}
.wizard.wizard-1 .wizard-nav .wizard-steps{
    justify-content: left !important;
}
.form fieldset {
    border : 1px solid #EBEDF3 !important;
    padding: 2.2rem;
    margin-bottom: 30px;
    border-radius: 5px;
}

.form fieldset legend{
    width: unset;
    padding-left: 10px;
    padding-right: 8px;
    text-transform: uppercase;
    font-size: 1rem !important;
}

.form fieldset legend h3{
    font-size: 1.3rem !important;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--dark-blue-color) !important;
    fill: #fff;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--dark-blue-color) !important;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px var(--dark-blue-color) !important;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
}

.success-h1 {
    font-size: 2rem !important;
}


.success-sub {
    font-weight: normal !important;
}




.alert-primary-dark-blue {
    background-color: #002147;
    border-color: #002147;
    color:#ffffff;
}








@media(max-width:991px){

    .admission-landing-aside .logo{
        display: none !important;
    }

    .logo-mobile{
        margin-top: -70px;
    }

    .landing-content-title h3{
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 2.3rem;
        padding-top: 2rem;
    }
    .landing-content-title p{
        font-weight: 900 !important;
        font-size: 3rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }

}

@media (min-width: 992px){
    .admission-landing-aside{
        width: 60% !important;
        max-width: none !important;

    }
    .landing-content-title h3{
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 2.4rem;
        padding-top: 2rem;
    }
    .landing-content-title p{
        font-weight: 900 !important;
        font-size: 3.2rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        line-height: 4rem;
        padding-bottom: 2rem;
    }

    .bgi-size-cover {
        background-size: cover;
        background-position: center;
    }

    .logo-mobile{
        display: none;
    }


}

.inpage-alert{
    text-align: center;
    min-height: 200px;
}

.inpage-alert .alert-text{
    color: --dark-blue-color !important;
    border: solid 1px --dark-blue-color !important;
}
.scrolltop{
    background-color: --dark-blue-color !important;
}

.wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step{

    flex: auto !important;
}

#progressbar { 
   width:100%;
   display:table;
}

#progressbar li {
display:table-cell;
}

#progressbar li:after{
    height: 3px !important;
}
#progressbar li:before{
    line-height: 27px !important;
}

#progressbar .active .text-primary {
    color: var(--dark-blue-color) !important;
}

.st0{
    fill:var(--dark-blue-color);
}
.stwhite0{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}

.stwhite1{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linejoin:bevel;stroke-miterlimit:10;}
